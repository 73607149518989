<template>
  <v-card elevation="0">
    <v-card-text class="pa-0">
      <v-layout wrap>
        <!-- <v-flex xs2>
          <img class="rl-avatar" src="@/assets/user.webp" alt="user-img">
        </v-flex> -->
        <v-flex xs12>
          <v-layout wrap>
            <v-flex xs12>
              <h1 class="r-title text-capitalize">{{ data.nama}}</h1>
              <span class="r-sub"> {{data.created_at}}}</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <p class="r-sub2 mt-1" v-html="data.pesan">
          </p>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider class="mt-1 mb-4"></v-divider>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DiscussionList',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState([
      'footer'
    ])
  }
}
</script>

<style scoped>
.r-title {
  font-family: 'Helvetica' !important;
  font-weight: bold;
  font-size: 1rem;
}
.r-sub {
  font-family: 'Helvetica' !important;
  font-weight: normal;
  color: #C0C0C0;
  font-size: 0.8rem;
}
.rl-avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
</style>