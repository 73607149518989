<template>
 <v-layout wrap id="mydiscussion">
    <v-flex xs12>
      <NotificationHeader />
    </v-flex>
    <v-flex xs12>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="#000"
        :grow="isMobile ? true : false"
      >
        <v-tab>
          <span>Waiting Reply </span>
          <span v-if="unreadNumber" class="ml-1"> ({{unreadNumber}})</span>
        </v-tab>
        <v-tab>
          <span>Replied</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" background-color="transparent">
        <v-tab-item>
          <v-card
            flat
          >
            <Unread v-if="tab === 0" @setTotalUnread="unreadNumber = $event"/>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card
            flat
          >
            <Read v-if="tab === 1" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </v-flex>
 </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import Read from '@/components/MyDiscussion/Read'
import Unread from '@/components/MyDiscussion/Unread'
import NotificationHeader from '@/components/Notification/NotificationHeader'

export default {
  name: 'DiscussionPage',
  components: { Read, Unread, NotificationHeader },
  computed: {
    ...mapState([
      'isMobile'
    ])
  },
  data () {
    return {
      tab: 0,
      unreadNumber: 0
    }
  },
  mounted () {
    this.setMetaInfo(null, 'Discussions')
    this.$store.commit('setIsHome', false)
    this.$store.commit('setIsLoginPage', false)
    this.$store.commit('setIsMemberPage', true)
    this.$store.commit('setActivePage', 'Discussions')
  }
}
</script>
