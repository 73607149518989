<template>
  <div class="discussion-detail">
    <RatingHeader title="DISCUSSIONS" @close="$emit('close')" />

    <div class="pl-3 pr-3 mb-8">
      <DiscussionCard :data="data"/>
      <template v-if="data && data.reply && data.reply.length > 0">
        <div v-for="(item,i) in  data.reply" :key="i">
          <DiscussionCard :data="item"/>
        </div>
      </template>
    </div>

    <div xs12 v-if="data && data.is_reply" class="dd-footer pa-1">
      <v-layout wrap>
        <v-flex xs10>
          <v-text-field
            v-model="text"
            placeholder="Write a message"
            filled
            rounded
            dense
            :hide-details="true"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <p class="mb-0" align="right">
            <v-btn fab small color="#000" @click="validate()">
              <v-icon color="#fff"> mdi-send</v-icon>
            </v-btn>
          </p>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/axios';
import DiscussionCard from '@/components/Rating/Discussion/DiscussionCard'
import RatingHeader from '@/components/Rating/RatingHeader'

export default {
  name: 'DiscussionDetail',
  components: { DiscussionCard, RatingHeader },
  props: {
    id: {
      type: String,
      default: ''
    },
    productCode: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState([
      'token'
    ])
  },
  data () {
    return {
      data: {},
      text: '',
      message: ''
    }
  },
  methods: {
    getDiscussionDetail () {
      this.$store.commit('setIsLoading', true)
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token,
        id_discussion: this.id
      }

      axios.get('/api/v1/pages/discussion_detail')
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = null
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    },
     validate () {
      if ((this.text && this.text.trim() === '') || this.text === '') {
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Oops, Anda belum mengisi pesannya')
      } else if (this.text && this.text.length > 500) {
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Maximal 500 Characters')
      } else { 
        this.submit()
      }
    },
    submit () {
      this.$store.commit('setIsLoading', true)

      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        product_code: this.productCode ? this.productCode :this.$route.params.id,
        token: this.token
      }

      let data = {
        product_code: this.productCode ? this.productCode :this.$route.params.id,
        pesan : this.text
      }

      axios.post('/api/v1/pages/discussion/'+ this.id, data)
        .then(response => {
          this.message = response.data.message
          this.getDiscussionDetail()
        })
        .catch(error => {
          this.errorHandling (error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    }
  },
  mounted () {
    this.getDiscussionDetail()
  }
}
</script>

<style scoped>
.discussion-detail {
  background-color: #fff;
  position: relative;
  padding: 0px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.dd-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;

  border-top: 1px solid #c0c0c0;
  background-color: #fff;
}
</style>