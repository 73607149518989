<template>
  <div>
    <v-layout wrap class="pl-1" v-for="(reply, idx) in datas" :key="idx">
      <v-flex xs4>
        <v-layout wrap>
          <v-flex xs1>
            <v-icon>mdi-reply</v-icon>
          </v-flex>
          <v-flex xs11>
            <h1 class="r-title">{{ reply.nama }}</h1>
            <span class="r-sub"> {{ reply.created_at }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="ml-7">
        <p class="r-sub2 mt-1" v-html="reply.pesan">
        </p>
      </v-flex>
    </v-layout>

    <div class="mt-2">
      <DiscussionForm 
        v-if="isLogin" 
        :id="id" 
        label="Reply Discussion" 
        :is-detail="true" 
        button-label="Send" 
        :success="success"
        @submit="submitDetail($event)"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/axios'
import DiscussionForm from '@/components/Rating/Dekstop/Discussion/DiscussionForm'

export default {
  name: 'ReplyCard',
  components: { DiscussionForm },
  props: {
    id: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    productCode: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState([
      'isLogin',
      'token'
    ])
  },
  data () {
    return {
      message: '',
      success: false,
      datas: []
    }
  },
  methods: {
    submitDetail ($event) {
      this.$store.commit('setIsLoading', true)

      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        product_code: this.productCode,
        token: this.token
      }

      let data = {
        product_code: this.productCode,
        pesan : $event.text
      }

      axios.post('/api/v1/pages/discussion/'+ $event.id, data)
        .then(response => {
          this.message = response.data.message
          this.success = true
          this.getDiscussionDetail(true)
        })
        .catch(error => {
          this.errorHandling (error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
          setTimeout(()=> {
            this.success = false
          })
        })
    },
    getDiscussionDetail (isReload) {
      this.$store.commit('setIsLoading', true)
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token,
        id_discussion: this.id
      }

      axios.get('/api/v1/pages/discussion_detail')
        .then(response => {
          if (response.data.status) {
            if (isReload) {
              this.datas = response.data.data.reply
            }
          } else {
            this.datas = null
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    },
    setDatas () {
      this.datas = this.items
    }
  },
  mounted () {
    this.getDiscussionDetail(false)
    this.setDatas()
  }
}
</script>

<style scoped>
.r-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666261;
}
.r-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #666261;
}
.r-sub2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #666261;
}
</style>