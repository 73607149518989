<template>
  <div class="discussion-dekstop">
    <v-textarea
      v-if="!isDetail || (isDetail && canReply)"
      v-model="text"
      class="text-input" 
      solo
      :rows="isDetail ? 2:4"
      :placeholder="label"
      :rules="rules"
      :hide-details="true"
    ></v-textarea>

    <p v-if="!isDetail || (isDetail && canReply)" align="right">
      <v-btn class="mt-4" tile width="20%" height="30" color="#000" @click="validate()">
        <span class="white--text"> {{buttonLabel}} </span>
      </v-btn>
    </p>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/axios'

export default {
  name: 'QuestionForm',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Ask Anything'
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    buttonLabel: {
      type: String,
      default: 'Submit'
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'token'
    ])
  },
  watch: {
    text (val) {
      if (val && val.length <= this.maxValue) {
        this.remaining = this.maxValue - this.text.length
      } else {
        this.remaining = this.maxValue
      }
    },
    success (val) {
      if (val) {
        this.text = ''
      }
    }
  },
  data () {
    return {
      text: '',
      rules: [v => v.length <= this.maxValue || 'Max 500 characters'],
      maxValue: 500,
      remaining: 500,
      data: null,
      err: '',
      canReply: false
    }
  },
  methods: {
    validate () {
      if ((this.text && this.text.trim() === '') || this.text === '') {
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Oops, Anda belum mengisi pesannya')
      } else if (this.text && this.text.length > 500) {
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Maximal 500 Characters')
      } else {
        if (this.isDetail) {
          this.$emit('submit', {
            id: this.id,
            text: this.text
          })
        } else {
          this.$emit('submit', this.text)
        }
      }
    },
    getDiscussionDetail () {
      this.$store.commit('setIsLoading', true)
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token,
        id_discussion: this.id
      }

      axios.get('/api/v1/pages/discussion_detail')
        .then(response => {
          if (response.data.status) {
            this.canReply = response.data.data.is_reply
          } else {
            this.data = null
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    }
  },
  mounted () {
    if (this.isDetail) {
      this.getDiscussionDetail()
    }
  }
}
</script>

<style scoped>
.od-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
.od-sub2 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #000000;
}
.od-sub3 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #000000;
}
.od-sub4 {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #666261;
}
.text-input >>> .v-label {
  font-size: 17px;
}
</style>