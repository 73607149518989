<template>
  <v-app-bar :class="withBorder ? 'appbar-custom mb-4' : 'appbar-custom mb-0'" color="#fff" :elevation="withBorder ? '' : 0">
    <v-icon class="ml-2" @click="$emit('close')">
      mdi-arrow-left
    </v-icon>
    <div class="mr-title-wrapper">
      <h1 class="mr-title"> {{ title }} </h1>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'RatingHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    withBorder: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.appbar-custom >>> .v-toolbar__content {
  padding: 0 !important;
}
.mr-title-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mr-title {
  color: #000000;
  font-size: 0.8rem !important;
  font-weight: 700;
}
</style>