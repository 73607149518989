<template>
  <v-layout wrap style="text-align:center">
    <v-pagination
      v-model="page"
      class="page-custom"
      color="black"
      :length="length"
      :total-visible="3"
      @input="toPage($event)"
    ></v-pagination>
  </v-layout>
</template>

<script>
export default {
  name: 'RatingPagination',
  props: {
    length: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      page: 1
    }
  },
  methods: {
    toPage ($event) {
      this.$emit('toPage', $event)
    }
  }
}
</script>

<style scoped>
.page-custom >>> .v-pagination__item {
  font-size: 12px !important;
  height: 20px !important;
  min-width: 20px !important;
  margin: 0.3rem 2px !important;
}
.page-custom >>> .v-pagination__navigation {
  height: 20px !important;
  width: 20px !important;
  margin: 0.3rem 2px !important;
}
.page-custom >>> .v-pagination__more {
  height: 20px !important;
  width: 20px !important;
}
</style>