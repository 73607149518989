<template>
  <div class="r-waiting mb-16">
    <div v-for="(item,i) in data" :key="i" class="mt-2">
      <DiscussionCard :data="item" @openDetailDesktop="handleOpenDekstop($event)" @openDetail="handleOpenDetail($event)"/>
      <v-divider v-if="!isMobile" class="mt-1 mb-1"></v-divider>
    </div>
    <div v-if="data && data.length === 0" class="mt-4">
      <span style="font-weight:bold;"> No Discussion submitted</span>
    </div>
    <div class="mt-4">
      <RatingPagination v-if="total && total > 1" :length="total" @toPage="page = $event" />
    </div>

    <v-dialog v-model="openDetail"
      :fullscreen="true"
      transition="dialog-bottom-transition"
      scrollable
    >
      <DiscussionDetail v-if="openDetail" :id="selectedItem.id_discussion" :product-code="selectedItem.id_discussion" @close="openDetail = false" />
    </v-dialog>
  </div>
</template>

<script>
import axios from '@/axios'
import { mapState } from 'vuex'
import DiscussionCard from '@/components/MyDiscussion/DiscussionCard'
import RatingPagination from '@/components/Rating/RatingPagination'
import DiscussionDetail from '@/components/Rating/Discussion/DiscussionDetail'


export default {
  name: 'Read',
  components: { DiscussionCard, RatingPagination, DiscussionDetail },
  props: {
    success: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'token'
    ])
  },
  watch: {
    page () {
      this.getData()
    },
    success () {
      this.getData()
    }
  },
  data () {
    return {
      page: 1,
      data: [],
      total: 0,
      error: '',
      selectedItem: null,
      openDetailDekstop: false,
      openDetail: false
    }
  },
  methods: {
    handleOpenDekstop (item) {
      this.selectedItem = item
      this.openDetailDekstop = true
    },
    handleOpenDetail (item) {
      this.selectedItem = item
      this.openDetail = true
    },
    getData () {
      this.$store.commit('setIsLoading', true)
      
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token,
        current_page: this.page,
        status: 'READ',
        max_record: 5
      }

      axios.get('/api/v1/pages/discussion')
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data.data_discussion
            this.total = response.data.data.total_page
          } else {
            this.data = []
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>