<template>
  <div class="notification-header">
    <h1 v-if="!isMobile">Notifications</h1>
    <p v-if="!isMobile" class="text mb-4">Stay informed by checking notifications</p>

    <div v-if="!isMobile" class="mb-4">
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        class="ml-1"
        width="200px"
        tile
        :color="tab === i ? 'black' : 'white'"
        @click="setActiveTab(i)"
      >
        <span :class="tab === i ? 'white--text' : ''"> {{item}}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NotificationHeader',
  computed: {
     ...mapState([
      'isMobile',
      'token'
    ])
  },
  data () {
    return {
      items: ['Reviews', 'Discussions'],
      tab: ''
    }
  },
  methods: {
    setActiveTab (val) {
      if (val === 1) {
        this.$router.push({'name' : 'MyDiscussion'})
      } else {
        this.$router.push({'name' : 'MyReview'})
      }
      this.tab = val
    },
    initActiveTab () {
      if (this.$route.name === 'MyReview') {
        this.tab = 0
      } else {
        this.tab = 1
      }
    }
  },
  mounted () {
    this.initActiveTab()
  }
}
</script>

<style scoped>
.text {
  color: #000000;
  margin-bottom: 5px;
  font-size: 0.9rem !important;
  font-style: normal !important;
}
</style>