<template>
  <v-card class="pt-2 pb-1 pl-4 pr-4" :elevation="isMobile ? '1' : '0'">
    <v-layout v-if="isMobile" wrap @click="$emit('openDetail', data)" v-ripple>
      <v-flex xs8>
        <p class="pc-title pointer">{{data.product_name}}</p>
      </v-flex>
      <v-flex xs4>
        <p class="pc-sub mb-0" align="right">{{ formatDate(data.order_date)}}</p>
      </v-flex>
      <v-flex xs12>
        <p class="pc-sub2 mt-2 mb-2 pointer">{{data.pesan}}</p>
      </v-flex>
    </v-layout>

    <v-layout v-else wrap style="position:relative">
      <v-flex xs6>
        <p class="pc-title mb-4 pointer" @click="toProduct(data)">{{data.product_name}}</p>
      </v-flex>
      <v-flex xs6>
        <p class="pc-sub mb-0" align="right">{{ formatDate(data.order_date)}}</p>
      </v-flex>
      <v-flex xs9>
        <p class="pc-sub2 mt-2 mb-2">{{data.pesan}}</p>
      </v-flex>
      <v-flex xs12 class="mt-4" v-if="seeDetail">
        <ReplyCard :items="data.reply" :id="data.id_discussion" :product-code="data.product_code"/>
      </v-flex>
      <v-flex xs12 v-if="data && data.reply && data.reply.length > 0">
        <p v-if="!seeDetail" class="mb-0" align="center" style="font-size:12px;cursor:pointer;" @click="openDetail">
          <span>See Detail</span>
          <v-icon x-small>mdi-chevron-down</v-icon>
        </p>

         <p v-else class="mb-0" align="center" style="font-size:12px;cursor:pointer;" @click="closeDetail">
          <span>Close Detail</span>
          <v-icon x-small>mdi-chevron-up</v-icon>
        </p>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ReplyCard from '@/components/MyDiscussion/ReplyCard'

export default {
  name: 'ProductCard',
  components: { ReplyCard },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
     ...mapState([
      'isMobile',
      'token'
    ])
  },
  data () {
    return {
      seeDetail: false
    }
  },
  methods: {
    openDetail () {
      this.seeDetail = true
    },
    closeDetail () {
       this.seeDetail = false
      this.$emit('reload')
    }
  }
}
</script>

<style scoped>
.pc-img {
  width: 100%;
}
.pc-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.pc-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #898989;
}
.pc-sub2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.pointer {
  cursor: pointer;
}
</style>